import React from "react";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div id="wrapper">
      <div
        id="content-wrapper"
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <div id="content">
          <div className="container-fluid">
            <div className="text-center">
              <div className="error mx-auto" data-text="404">
                404
              </div>
              <p className="lead text-gray-900 mb-5">Page Not Found</p>
              <Link to="/dashboard">&larr; Go Back to Dashboard</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
