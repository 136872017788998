import React from "react";
import { Link, useNavigate } from "react-router-dom";
import UserData from "../../storage/UserData";

const Sidebar = () => {
  const navigate = useNavigate();

  const user = UserData();

  const handleLogout = () => {
    sessionStorage.removeItem("userData");
    navigate("/login");
  };

  return (
    <ul
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      {/* Sidebar - Brand */}
      <a
        className="sidebar-brand d-flex align-items-center justify-content-center"
        href="/"
      >
        <div className="sidebar-brand-icon">
          <img
            src="/img/radiant.jpg"
            alt=""
            className="rounded-circle"
            style={{ height: "60px", width: "60px" }}
          />
        </div>
        <div className="sidebar-brand-text mx-3"></div>
      </a>

      {/* Divider */}
      <hr className="sidebar-divider my-0" />

      {/* Nav Item - Dashboard */}

      <li className="nav-item">
        <Link className="nav-link" to="/dashboard">
          <i className="fas fa-fw fa-home"></i>
          <span>Dashboard</span>
        </Link>
      </li>

      {/* Divider */}
      <hr className="sidebar-divider" />

      {/* Heading */}
      {/* <div className="sidebar-heading">Interface</div> */}

      {/* Nav Item - Pages Collapse Menu */}
      {user && user.user_type === "Owner" ? (
        <li className="nav-item">
          <Link className="nav-link" to="/subscription">
            <i className="fas fa-fw fa-money-bill-alt"></i>
            <span>Subscription</span>
          </Link>
        </li>
      ) : null}
      {user && user.user_type === "Owner" ? (
        <li className="nav-item">
          <Link className="nav-link" to="/shop">
            <i className="fas fa-fw fa-store"></i>
            <span>Shop</span>
          </Link>
        </li>
      ) : null}
      {user && user.user_type === "Owner" ? (
        <li className="nav-item">
          <Link className="nav-link" to="/account">
            <i className="fas fa-fw fa-landmark"></i>
            <span>Account</span>
          </Link>
        </li>
      ) : null}

      <li className="nav-item">
        <Link className="nav-link" to="/contact">
          <i className="fas fa-fw fa-address-book"></i>
          <span>Contact</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link className="nav-link" to="/about">
          <i className="fas fa-fw fa-address-card"></i>
          <span>About</span>
        </Link>
      </li>

      {/* Divider */}
      {/* <hr className="sidebar-divider" /> */}

      <li className="nav-item">
        <Link className="nav-link" to="/login" onClick={handleLogout}>
          <i className="fas fa-fw fa-sign-out-alt"></i>
          <span>Logout</span>
        </Link>
      </li>
    </ul>
  );
};

export default Sidebar;
