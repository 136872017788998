import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="sticky-footer bg-white">
      <div className="container">
        <div className="copyright">
          <span className="float-left">
            Copyright &copy; Radiant Cash Management Services Ltd {year.toString()}
          </span>
          <span className="float-right">
            <Link target="_blank" to="/policy"> Privacy and Policy </Link> | 
            <Link target="_blank" to="/terms"> Terms and Conditions </Link> | 
            <Link target="_blank" to="/services"> Services with Pricing </Link> | 
            <Link target="_blank" to="/refundpolicy"> Refund and Cancellation Policy </Link>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
