import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children, userType }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const userData = sessionStorage.getItem("userData");
    if (!userData) {
      navigate("/login");
    } else {
      const user = JSON.parse(userData);
      if (userType && user.user_type !== userType) {
        navigate("/dashboard");
      }
    }
  }, [navigate, userType]);

  return children;
};

export default ProtectedRoute;
