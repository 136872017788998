import { useState, useEffect } from "react";

const UserData = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUserData = sessionStorage.getItem("userData");

    if (storedUserData) {
      setUser(JSON.parse(storedUserData));
    }
  }, []);

  return user;
};

export default UserData;
