import cryptoJs from "crypto-js";
import SecretKey from "./SecretKey";

const EncryptPayload = (data) => {
  const base64EncodedData = cryptoJs.enc.Base64.stringify(cryptoJs.enc.Utf8.parse(data));

  const iv = cryptoJs.lib.WordArray.random(16);

  const encrypted = cryptoJs.AES.encrypt(base64EncodedData, cryptoJs.enc.Utf8.parse(SecretKey), {
    iv: iv,
    mode: cryptoJs.mode.CBC,
    padding: cryptoJs.pad.Pkcs7,
  });

  const encryptedBase64 = encrypted.ciphertext.toString(cryptoJs.enc.Base64);
  const ivBase64 = iv.toString(cryptoJs.enc.Base64);

  return [{payload: encryptedBase64, iv: ivBase64}];
};

  export default EncryptPayload;