import React, { useEffect, useState } from "react";
import axios from "../axios/url";
import EncryptPayload from "../../utils/EncryptPayload";
import DecryptPayload from "../../utils/DecryptPayload";
import Authorization from "../../utils/Authorization";
import UserData from "../../storage/UserData";
import DataTable from "datatables.net-dt";
import { Link } from "react-router-dom";

const Subscription = () => {
  const [selectAll, setSelectAll] = useState(false);
  const [pointid, setPointid] = useState([]);
  const [pointsData, setpointsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = UserData();
  const [isNewPoint, setIsNewPoint] = useState(null);

  const onCheckHandle = (pointidin) => {
    const inputpointid = pointid.includes(pointidin);
    if (!inputpointid) {
      setPointid([...pointid, pointidin]);
    } else {
      const newPointid = pointid.filter((id) => id !== pointidin);
      setPointid(newPointid);
    }
  };

  useEffect(() => {
    if (user && (isNewPoint !== null)) {
      const fetchSubscriptionPoints = async () => {
        setLoading(true);
        setPointid([]);
        setpointsData([]);
        setSelectAll(false);
        var type_point = "FetchSubscriptionPoints";
        if (isNewPoint === true) {
          type_point = "FetchSubscriptionPointsNew";
        }
        const requestData = {
          type: type_point,
          customer_id: user.customer_id,
        };

        const requestDataString = JSON.stringify(requestData);

        const EncData = EncryptPayload(requestDataString);
        const PayloadEnc = EncData[0].payload.toString();
        const IvEnc = EncData[0].iv.toString();

        try {
          const checkResponse = await axios.post(
            "/custSubscriptionPointsRIC.php",
            PayloadEnc,
            {
              headers: {
                "Content-Type": "text/plain",
                Authorization: Authorization,
                EncAuth: IvEnc,
              },
            }
          );

          const encAuthHeaderValue = checkResponse.headers["encauth"];
          const decryptedData = DecryptPayload(
            checkResponse.data,
            encAuthHeaderValue
          );

          const parsedData = JSON.parse(decryptedData);

          if (parsedData.status === "success") {
            setpointsData(parsedData.data);
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchSubscriptionPoints();
    }
  }, [isNewPoint, user]);

  useEffect(() => {
    if (pointsData.length > 0) {
      new DataTable("#dataTable");
      const expiredIds = pointsData
        .filter((row) => row.subscription_days_status === "Expired")
        .map((row) => row.point_id);

      setPointid(expiredIds);
    }
  }, [pointsData]);

  const handleSelectAll = () => {
    if (selectAll) {
      setPointid([]);
    } else {
      const allIds = pointsData.map((row) => row.point_id);
      setPointid(allIds);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    const allIds = pointsData.map((row) => row.point_id);
    setSelectAll(pointid.length === allIds.length);
  }, [pointid, pointsData]);

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-900">Subscription</h1>
      </div>
      <div className="card shadow mb-4">
        <div className="card-body">
          <button
            type="button"
            name="newpoint"
            style={isNewPoint === true ? { backgroundColor: "#4d72de" } : null}
            onClick={() => setIsNewPoint(true)}
            className="btn btn-sm btn-dark"
          >
            Pay For Newly Added Outlet
          </button>
          &nbsp;&nbsp;
          <button
            type="button"
            name="oldpoint"
            style={isNewPoint === false ? { backgroundColor: "#4d72de" } : null}
            onClick={() => setIsNewPoint(false)}
            className="btn btn-sm btn-dark"
          >
            Pay For Renewal of Existing Outlet
          </button>
          <hr />
          {pointsData.length > 0 ? (
            <>
              <div className="row">
                <div className="col-lg-4 text-center">
                  <h5 className="font-weight-bold float-left">
                    Total points: {pointsData.length}
                  </h5>
                </div>
                <div className="col-lg-4 text-center">
                  <h5 className="font-weight-bold float-right">
                    Total selected points: {pointid.length}
                  </h5>
                </div>
                <div className="col-lg-4 text-right">
                  <Link
                    to={{ pathname: "/subscriptionconfirmation" }}
                    state={{
                      pointidPassed: pointid,
                      pointsDataPassed: pointsData,
                    }}
                    className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                  >
                    <i className="fab fa-cc-amazon-pay fa-sm text-white-50"></i>
                    Proceed to Pay
                  </Link>
                </div>
              </div>
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                >
                  <thead className="text-white">
                    <tr className="bg-gradient-primary">
                      <th
                        className="text-center"
                        style={{ backgroundColor: "#0442f7" }}
                      >
                        Select All
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th>Shop ID</th>
                      <th>Pincode</th>
                      <th>Location</th>
                      <th>Address</th>
                      <th>Contact Name</th>
                      <th>Number</th>
                      <th>Subscription Plan</th>
                      {!isNewPoint ? (
                        <>
                          <th>Expiry Date</th>
                          <th>Days Remaining</th>
                          <th>Status</th>
                        </>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody className="text-gray-900">
                    {loading ? (
                      <tr>
                        <td colSpan="6">
                          <img src="/img/loading.gif" alt="Loading..." />
                        </td>
                      </tr>
                    ) : pointsData.length > 0 ? (
                      pointsData.map((pointData, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              checked={pointid.includes(pointData.point_id)}
                              name=""
                              id=""
                              onChange={() => onCheckHandle(pointData.point_id)}
                            />
                          </td>
                          <td>{pointData.point_id}</td>
                          <td>{pointData.pincode}</td>
                          <td>{pointData.location}</td>
                          <td>{pointData.customer_address}</td>
                          <td>{pointData.shop_contact_name}</td>
                          <td>{pointData.customer_mobile_number}</td>
                          <td>{pointData.subscription}</td>
                          { !isNewPoint ? (
                            <>
                              <td>{pointData.subscription_expire_date}</td>
                              <td>{pointData.subscription_days_remaining}</td>
                              <td>{pointData.subscription_days_status}</td>
                            </>
                          ) : null }
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">No Records Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : isNewPoint !== null ? (
            <h5>No data Available!!</h5>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
