import React, { useCallback, useEffect, useState } from "react";
import axios from "../axios/url";
import EncryptPayload from "../../utils/EncryptPayload";
import DecryptPayload from "../../utils/DecryptPayload";
import Authorization from "../../utils/Authorization";
import UserData from "../../storage/UserData";
import Select from "react-select";
// import DataTable from "datatables.net-dt";
// import $ from "jquery";
import { Modal, Button, Form } from "react-bootstrap";
const Account = () => {
  const user = UserData();

  //** Fetch Account Data Start **/
  const [accountData, setAccountData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonState, setButtonState] = useState("getOTPAddAccount");

  const fetchAccountData = useCallback(async () => {
    setLoading(true);
    const requestData = {
      type: "FetchAccount",
      customer_id: user.customer_id,
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post(
        "/custAccountRIC.php",
        PayloadEnc,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: Authorization,
            EncAuth: IvEnc,
          },
        }
      );

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
        setAccountData(parsedData.data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }, [user?.customer_id]);
  //** Fetch Account Data End **/

  useEffect(() => {
    if (user) {
      fetchAccountData();
    }
  }, [user, fetchAccountData]);

  const options = [
    { value: "Current Account (CA)", label: "Current Account (CA)" },
    { value: "Savings Account (SB)", label: "Savings Account (SB)" },
    { value: "Cash Credit (CC)", label: "Cash Credit (CC)" },
    { value: "Overdraft (OD)", label: "Overdraft (OD)" },
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    setFormData({
      ...formData,
      accountType: option ? option.value : "",
    });
  };

  const [formData, setFormData] = useState({
    customerID: user ? user.customer_id : "",
    bankName: "",
    accountNumber: "",
    confirmaccountNumber: "",
    ifscCode: "",
    branchName: "",
    accountType: "",
    otp: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleAddAccount = async () => {
    const requestData = {
      type: "AddAccount",
      customer_id: user ? user.customer_id : "",
      bank_name: formData.bankName,
      account_number: formData.accountNumber,
      ifsc_code: formData.ifscCode,
      branch_name: formData.branchName,
      account_type: formData.accountType,
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post(
        "/custAccountRIC.php",
        PayloadEnc,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: Authorization,
            EncAuth: IvEnc,
          },
        }
      );

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
        alert(parsedData.message);
        setFormData({
          customerID: user ? user.customer_id : "",
          bankName: "",
          accountNumber: "",
          confirmaccountNumber: "",
          ifscCode: "",
          branchName: "",
          accountType: "",
        });
        setSelectedOption(null);
        fetchAccountData();
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //** Handle Get OTP Add Account Start */

  const handlegetOTPAddAccount = async (e) => {
    e.preventDefault();
    const {
      bankName,
      accountNumber,
      confirmaccountNumber,
      ifscCode,
      branchName,
      accountType,
    } = formData;

    const regex = /^[a-zA-Z0-9\s]*$/;

    if (
      !bankName ||
      !accountNumber ||
      !confirmaccountNumber ||
      !ifscCode ||
      !branchName ||
      !accountType
    ) {
      alert("All Fields are Required");
      return;
    }
    if (!regex.test(bankName)) {
      alert("Bank Name should not contain special characters");
      return;
    }
    if (!regex.test(accountNumber)) {
      alert("Account Number should not contain special characters");
      return;
    }

    if (!regex.test(confirmaccountNumber)) {
      alert("Confirm Account Number should not contain special characters");
      return;
    }

    if (!regex.test(ifscCode)) {
      alert("IFSC Code should not contain special characters");
      return;
    }

    if (!regex.test(branchName)) {
      alert("Branch Name should not contain special characters");
      return;
    }

    if (accountNumber !== confirmaccountNumber) {
      alert("Your Account Number and Confirm Account Number do not match");
      return;
    }
    if (ifscCode.length !== 11) {
      alert("IFSC Code should be 11 digits");
      return;
    }
    if (ifscCode[4] !== "0") {
      alert("IFSC Code fifth digit should be 0");
      return;
    }
    const requestData = {
      type: "GetOTPAddAccount",
      customer_id: user ? user.customer_id : "",
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post(
        "/custAccountRIC.php",
        PayloadEnc,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: Authorization,
            EncAuth: IvEnc,
          },
        }
      );

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);
      if (parsedData.status === "success") {
        setButtonState("submitOTPAddAccount");
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  //** Handle Add Account End */

  const handleSubmitOTPAddAccount = async (e) => {
    e.preventDefault();

    const requestData = {
      type: "SubmitOTPAddAccount",
      customer_mobile_number: user ? user.customer_mobile_number : "",
      otp: formData.otp,
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post(
        "/custAccountRIC.php",
        PayloadEnc,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: Authorization,
            EncAuth: IvEnc,
          },
        }
      );

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
        handleAddAccount();
        setButtonState("getOTPAddAccount");
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //** Handle Suspend Account Start **/

  const [showModal, setShowModal] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const [bankID, setBankID] = useState("");
  const [accountAction, setaccountAction] = useState("");

  const handleClose = () => {
    setShowModal(false);
    setRemarks("");
    setOtp("");
    setOtpSent(false);
  };

  const handleShowSuspend = (bank_id) => {
    setaccountAction("suspend");
    setOtpSent(false);
    setBankID(bank_id);
    setShowModal(true);
  };

  const handleShowActivate = (bank_id) => {
    setaccountAction("active");
    setOtpSent(true);
    setBankID(bank_id);
    setShowModal(true);
    handleGetOtpActivateAccount();
  };

  const handleSuspendAccount = async () => {
    const requestData = {
      type: "DeleteAccount",
      customer_id: user ? user.customer_id : "",
      bank_id: bankID,
      remarks: remarks,
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post(
        "/custAccountRIC.php",
        PayloadEnc,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: Authorization,
            EncAuth: IvEnc,
          },
        }
      );

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);
      alert(parsedData.message);

      if (parsedData.status === "success") {
        fetchAccountData();
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleGetOtpSuspendAccount = async () => {
    if (remarks === "") {
      alert("Please enter the suspend remarks");
      return;
    }
    const requestData = {
      type: "GetOTPActionAccount",
      customer_id: user ? user.customer_id : "",
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post(
        "/custAccountRIC.php",
        PayloadEnc,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: Authorization,
            EncAuth: IvEnc,
          },
        }
      );

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
        setOtpSent(true);
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Account Suspend Submit OTP
  const handleSubmitOtpSuspendAccount = async () => {
    if (otp === "") {
      alert("Please enter the OTP");
      return;
    }

    const requestData = {
      type: "SubmitOTPActionAccount",
      customer_id: user ? user.customer_id : "",
      customer_mobile_number: user ? user.customer_mobile_number : "",
      otp,
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post(
        "/custAccountRIC.php",
        PayloadEnc,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: Authorization,
            EncAuth: IvEnc,
          },
        }
      );

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
        handleSuspendAccount();
        handleClose();
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //** Handle Suspend Account End **/

  //** Handle Activate Account Start **/
  const handleActivateAccount = async () => {
    const requestData = {
      type: "ActivateAccount",
      customer_id: user ? user.customer_id : "",
      bank_id: bankID,
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post(
        "/custAccountRIC.php",
        PayloadEnc,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: Authorization,
            EncAuth: IvEnc,
          },
        }
      );

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
        alert(parsedData.message);
        fetchAccountData();
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleGetOtpActivateAccount = async () => {
    const requestData = {
      type: "GetOTPActionAccount",
      customer_id: user ? user.customer_id : "",
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post(
        "/custAccountRIC.php",
        PayloadEnc,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: Authorization,
            EncAuth: IvEnc,
          },
        }
      );

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmitOtpActivateAccount = async () => {
    if (otp === "") {
      alert("Please enter the OTP");
      return;
    }

    const requestData = {
      type: "SubmitOTPActionAccount",
      customer_id: user ? user.customer_id : "",
      customer_mobile_number: user ? user.customer_mobile_number : "",
      otp,
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post(
        "/custAccountRIC.php",
        PayloadEnc,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: Authorization,
            EncAuth: IvEnc,
          },
        }
      );

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
        handleActivateAccount();
        handleClose();
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  //** Handle Activate Account End **/

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-900">Account</h1>
      </div>
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-sm-flex align-items-center justify-content-between mb-4">
          <h6 className="m-0 font-weight-bold text-primary">Add Account</h6>
        </div>
        <div className="card-body ml-4">
          <form action="" autoComplete="off">
            {buttonState === "getOTPAddAccount" ? (
              <>
                <div className="form-row">
                  <div className="form-group col-md-4 mr-4">
                    <label className="text-gray-900">
                      Bank Name <span className="text-lg text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      name="bankName"
                      value={formData.bankName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group col-md-3 mr-4">
                    <label className="text-gray-900">
                      Account Number{" "}
                      <span className="text-lg text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      name="accountNumber"
                      value={formData.accountNumber}
                      onChange={handleInputChange}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </div>
                  <div className="form-group col-md-3 mr-4">
                    <label className="text-gray-900">
                      Confirm Account Number{" "}
                      <span className="text-lg text-danger">*</span>
                    </label>

                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"} // Toggling between text and password
                        className="form-control form-control-user"
                        name="confirmaccountNumber"
                        value={formData.confirmaccountNumber}
                        onChange={handleInputChange}
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text"
                          onClick={togglePasswordVisibility}
                        >
                          <i
                            className={
                              showPassword ? "far fa-eye" : "far fa-eye-slash"
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-3 mr-4">
                    <label className="text-gray-900">
                      IFSC Code <span className="text-lg text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      name="ifscCode"
                      value={formData.ifscCode}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group col-md-4 mr-4">
                    <label className="text-gray-900">
                      Branch Name <span className="text-lg text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      name="branchName"
                      value={formData.branchName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group col-md-3 mr-4">
                    <label className="text-gray-900">
                      Account Type{" "}
                      <span className="text-lg text-danger">*</span>
                    </label>
                    <Select
                      value={selectedOption}
                      onChange={handleSelectChange}
                      options={options}
                      placeholder="Select Account Type"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-3">
                    {buttonState === "getOTPAddAccount" ? (
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={handlegetOTPAddAccount}
                      >
                        Get OTP
                      </button>
                    ) : null}
                  </div>
                </div>
              </>
            ) : (
              <>
                <p className="font-weight-bold text-danger">
                  Please enter the OTP to add account details. An OTP has been
                  sent to your registered mobile number
                </p>
                <div className="form-row">
                  <div className="form-group col-md-3 mr-4">
                    <label className="text-gray-900">
                      OTP <span className="text-lg text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-user"
                      name="otp"
                      value={formData.otp}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-3">
                    {buttonState === "submitOTPAddAccount" ? (
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={handleSubmitOTPAddAccount}
                      >
                        Submit OTP
                      </button>
                    ) : null}
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {accountAction === "active"
              ? "Activate Account"
              : "Suspend Account"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {accountAction === "active" ? (
              otpSent && (
                <Form.Group className="mb-3" controlId="otp">
                  <p className="font-weight-bold text-danger">
                    Please enter the OTP to activate account details. An OTP has
                    been sent to your registered mobile number
                  </p>
                  <Form.Label>OTP</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </Form.Group>
              )
            ) : (
              <>
                {!otpSent && (
                  <Form.Group className="mb-3" controlId="remarks">
                    <Form.Label>Remarks</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Remarks"
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                    />
                  </Form.Group>
                )}

                {otpSent && (
                  <Form.Group className="mb-3" controlId="otp">
                    <p className="font-weight-bold text-danger">
                      Please enter the OTP to suspend account details. An OTP
                      has been sent to your registered mobile number
                    </p>
                    <Form.Label>OTP</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </Form.Group>
                )}
              </>
            )}

            {accountAction === "active" ? (
              !otpSent ? (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={handleGetOtpActivateAccount}
                >
                  Get OTP
                </Button>
              ) : (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={handleSubmitOtpActivateAccount}
                >
                  Submit OTP
                </Button>
              )
            ) : !otpSent ? (
              <Button
                variant="primary"
                size="sm"
                onClick={handleGetOtpSuspendAccount}
              >
                Get OTP
              </Button>
            ) : (
              <Button
                variant="primary"
                size="sm"
                onClick={handleSubmitOtpSuspendAccount}
              >
                Submit OTP
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>

      <div className="card shadow mb-4">
        <div className="card-header py-3 d-sm-flex align-items-center justify-content-between mb-4">
          <h6 className="m-0 font-weight-bold text-primary">View Account</h6>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%">
              <thead className="text-light">
                <tr className="bg-gradient-primary text-center">
                  <th>S.No</th>
                  <th>Bank Name</th>
                  <th>Account Number</th>
                  <th>IFSC Code</th>
                  <th>Branch Name</th>
                  <th>Account Type</th>
                  <th>Account Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="text-gray-900">
                <tr>
                  <th className="text-gray-900" colSpan="8">
                    Active Accounts
                  </th>
                </tr>
                {loading ? (
                  <tr>
                    <td colSpan="8">
                      <img src="/img/loading.gif" alt="Loading..." />
                    </td>
                  </tr>
                ) : accountData.length > 0 ? (
                  accountData
                    .filter((account) => account.account_status === "Active")
                    .map((account, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{account.bank_name}</td>
                        <td>{account.masked_account_number}</td>
                        <td>{account.ifsc_code}</td>
                        <td>{account.branch_name}</td>
                        <td>{account.account_type}</td>
                        <td>{account.account_status}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() => handleShowSuspend(account.bank_id)}
                          >
                            Suspend
                          </button>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="8">No Records Found</td>
                  </tr>
                )}
                <tr>
                  <th className="text-gray-900" colSpan="8">
                    Suspended Accounts
                  </th>
                </tr>
                {loading ? (
                  <tr>
                    <td colSpan="8">
                      <img src="/img/loading.gif" alt="Loading..." />
                    </td>
                  </tr>
                ) : accountData.length > 0 ? (
                  accountData
                    .filter((account) => account.account_status === "Suspend")
                    .map((account, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{account.bank_name}</td>
                        <td>{account.masked_account_number}</td>
                        <td>{account.ifsc_code}</td>
                        <td>{account.branch_name}</td>
                        <td>{account.account_type}</td>
                        <td>{account.account_status}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-success btn-sm"
                            onClick={() => handleShowActivate(account.bank_id)}
                          >
                            Activate
                          </button>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="8">No Records Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
