import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layouts/Layout";
import Subscription from "./components/Subscription/Subscription";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import ForgotLoginPin from "./components/ForgotLoginPin";
import Register from "./components/Register/Register";
import Shop from "./components/Shop/Shop";
import NotFound from "./components/NotFound";
import Account from "./components/Account/Account";
import Contact from "./components/Contact";
import About from "./components/About";
import Policy from "./components/Policy";
import Terms from "./components/Terms";
import Services from "./components/Services";
import RefundPolicy from "./components/RefundPolicy";
import SubscriptionConfirmation from "./components/Subscription/SubscriptionConfirmation";
import SubscriptionReview from "./components/Subscription/SubscriptionReview";

function App() {
  return (
    <Routes>
      {/* Public route */}
      <Route path="login" element={<Login />} />
      <Route path="/" element={<Login />} />
      <Route path="resetpin" element={<ForgotLoginPin />} />
      <Route path="register" element={<Register />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/policy" element={<Policy />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/services" element={<Services />} />
      <Route path="/refundpolicy" element={<RefundPolicy />} />
      {/* Protected routes */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      >
        <Route
          path="dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="shop"
          element={
            <ProtectedRoute userType="Owner">
              <Shop />
            </ProtectedRoute>
          }
        />
        <Route
          path="subscription"
          element={
            <ProtectedRoute>
              <Subscription />
            </ProtectedRoute>
          }
        />
        <Route
          path="subscriptionconfirmation"
          element={
            <ProtectedRoute>
              <SubscriptionConfirmation />
            </ProtectedRoute>
          }
        />
        <Route
          path="subscriptionreview"
          element={
            <ProtectedRoute>
              <SubscriptionReview />
            </ProtectedRoute>
          }
        />
        <Route
          path="account"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route 
        path="/contact" 
        element={
          <ProtectedRoute>
            <Contact />
          </ProtectedRoute>
          } 
        />
        <Route
        path="/about"
        element={
        <ProtectedRoute>
          <About />
        </ProtectedRoute>
        } 
        />
      </Route>
    </Routes>
  );
}

export default App;
