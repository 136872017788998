import "../login.css";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EncryptPayload from "../utils/EncryptPayload";
import DecryptPayload from "../utils/DecryptPayload";
import Authorization from "../utils/Authorization";
import axios from "./axios/url";
const LoginNew = () => {
  const navigate = useNavigate();

  const [buttonState, setButtonState] = useState("login");
  const [pinData, setPinData] = useState({});
  const [formData, setFormData] = useState({
    loginPin: "",
    otp: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const checkLogin = async (e) => {
    e.preventDefault();
    const { loginPin } = formData;
    if (!loginPin) {
      alert("Enter Login Pin Number");
      return;
    }
    if (loginPin.length !== 6) {
      alert("Login Pin should be 6 digits");
      return;
    }

    const requestData = {
      type: "CustomerLoginCheckRIC",
      login_pin: formData.loginPin,
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post("/custLoginRIC.php", PayloadEnc, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: Authorization,
          EncAuth: IvEnc,
        },
      });

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
        setButtonState("otp");
        const { customer_id, user_type } = parsedData.data;
        setPinData({ customer_id, user_type });
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const checkOTP = async (e) => {
    e.preventDefault();
    const { otp } = formData;
    if (!otp) {
      alert("Enter OTP");
      return;
    }
    const { customer_id, user_type } = pinData;

    const requestData = {
      type: "CustomerLoginRIC",
      login_pin: formData.loginPin,
      otp: formData.otp,
      customer_id: customer_id,
      user_type: user_type,
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const verifyResponse = await axios.post("/custLoginRIC.php", PayloadEnc, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: Authorization,
          EncAuth: IvEnc,
        },
      });

      const encAuthHeaderValue = verifyResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        verifyResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
        sessionStorage.setItem("userData", JSON.stringify(parsedData.data));
        navigate("/dashboard");
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <nav
        className="navbar"
        style={{
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#00172d",
          height: "80px",
        }}
      >
        <div
          className="actions text-light"
          style={{ cursor: "pointer", fontSize: "30px" }}
        >
          RADIANT INSTA CREDIT
        </div>
      </nav>
      <div className="container">
        <form autoComplete="off">
          <div className="row justify-content-md-center">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
              <div className="login-screen">
                <div className="login-box">
                  <Link to="/login" className="login-logo">
                    <img src="/img/logo.png" width="70" height="70" alt="" />
                  </Link>
                  <h5 className="text-gray-900" style={{ fontWeight: "bold" }}>
                    Welcome back,
                    <br />
                    Please Login to your Account.
                  </h5>
                  <div className="form-group">
                    {buttonState === "login" ? (
                      <input
                        type="text"
                        className="form-control"
                        id="loginPin"
                        name="loginPin"
                        placeholder="Enter Login Pin"
                        value={formData.loginPin}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        id="otp"
                        name="otp"
                        placeholder="Enter OTP"
                        value={formData.otp}
                        onChange={handleInputChange}
                      />
                    )}
                  </div>
                  <div className="actions mb-4 align-left">
                    {buttonState === "login" ? (
                      <input
                        type="submit"
                        className="btn btn-primary text-light"
                        value="Get OTP"
                        style={{ background: "#00172d", fontSize: "12px" }}
                        onClick={checkLogin}
                      />
                    ) : (
                      <>
                        <input
                          type="submit"
                          className="btn btn-primary text-light"
                          value="Submit"
                          style={{ background: "#00172d", fontSize: "12px" }}
                          onClick={checkOTP}
                        />
                        <Link
                          className="link text-primary"
                          to=""
                          style={{ marginLeft: "40%", fontWeight: "bold" }}
                          onClick={checkLogin}
                        >
                          Resend OTP
                        </Link>
                      </>
                    )}
                    {buttonState === "login" ? (
                      <Link
                        className="link text-primary"
                        to="/resetpin"
                        style={{ marginLeft: "15%", fontWeight: "bold" }}
                      >
                        Generate / Forgot Pin?
                      </Link>
                    ) : null}
                  </div>
                  <hr />
                  {buttonState === "login" ? (
                    <div className="" style={{ marginLeft: "20%" }}>
                      Are You New For Insta Credit? <br />
                      <Link
                        className="link text-primary"
                        to="/register"
                        style={{
                          marginLeft: "20%",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        Register !
                      </Link>
                    </div>
                  ) : (
                    <div className="" style={{ marginLeft: "" }}>
                      <Link
                        className="link text-primary"
                        to="/login"
                        onClick={() => setButtonState("login")}
                        style={{
                          marginLeft: "20%",
                          fontSize: "14px",
                          fontWeight: "bold",
                          textDecoration: "none",
                        }}
                      >
                        Go Back to Login?
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <footer className="sticky-footer">
        <div className="footer">
          <div className="copyright text-center my-auto">
            <span className="text-light">
              Copyright &copy; Radiant Cash Management Services Ltd 2024
            </span>
            <span className="float-right text-light">
              <Link className="text-light" to="/policy" target="_blank">
                Privacy and Policy
              </Link>
              &nbsp;|&nbsp;
              <Link to="/terms" target="_blank" className="text-light">
                Terms and Conditions
              </Link>
              &nbsp;|&nbsp;
              <Link to="/services" target="_blank" className="text-light">
                Services with Pricing
              </Link>
              &nbsp;|&nbsp;
              <Link to="/refundpolicy" target="_blank" className="text-light">
                Refund and Cancellation Policy
              </Link>
            </span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default LoginNew;
