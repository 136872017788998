const RefundPolicy = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="card pt-3">
                    <div className="card-header bg-primary text-white">
                        <h5 className="card-title">Radiant Insta Credit</h5>
                    </div>
                    <div className="card-body text-dark">
                        <p><b className="font-weight-bold">Cancellation / Termination Clause:</b> Customer can choose not to renew the Monthly Subscription at the end of the subscription period.</p>
                        <p><b className="font-weight-bold">Refund:</b> No Refund applicable.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RefundPolicy;