import React from "react";

const About = () => {
  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">About Us</h1>
      </div>
      <h3>RADIANT CASH MANAGEMENT SERVICES LIMITED</h3>
      <p><b className="font-weight-bold">Radiant Cash Management Services Limited (RCMS)</b> - an ISO 9001 – 2015 certified company, headquartered out of Chennai was incorporated on 23rd March, 2005 (under the Companies Act, 1956).</p>
      <p>RCMS was founded by Colonel David Devasahayam under the name of Radiant Cash Management Services Private Limited.</p>
      <p>RCMS launched its IPO in Dec 2022 and was listed on the BSE / NSE in Jan 2023.</p>
      <p>We are an integrated cash logistics player with a leading presence in the Retail Cash Management (“RCM”) segment of the cash management services industry in India and are one of the largest players in the RCM segment in terms of network locations / touchpoints served.</p>
      <p>We provide services across 14,000+ Pin codes in India covering all districts with more than 60,000+ touch points across 5,500+ locations.</p>
    </div>
  );
};

export default About;
