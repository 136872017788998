import React, { useState } from "react";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import axios from "../axios/url";
import EncryptPayload from "../../utils/EncryptPayload";
import DecryptPayload from "../../utils/DecryptPayload";
import Authorization from "../../utils/Authorization";

const ownership = [
  { value: "Limited", label: "Limited" },
  { value: "Private Ltd", label: "Private Ltd" },
  { value: "LLP", label: "LLP" },
  { value: "Partnership", label: "Partnership" },
  { value: "Proprietor", label: "Proprietor" },
  { value: "Individual", label: "Individual" },
];

const Register = () => {
  const navigate = useNavigate();

  const [buttonState, setButtonState] = useState("register");

  //** Location Fetch Start **/

  const [locationData, setLocationData] = useState({
    state: "",
    district: "",
    locations: [],
  });
  const [selectedLocation, setSelectedLocation] = useState(null);

  const options = locationData.locations.map((loc) => ({
    value: loc.location,
    label: loc.location,
    loc_id: loc.loc_id,
  }));

  //Location Select Options
  const handleLocationChange = (option) => {
    setSelectedLocation(option);
    setFormData({
      ...formData,
      location: option ? option.value : "",
      locationID: option ? option.loc_id : "",
    });
  };
  //Location Fetch API Call
  const handleLocation = async () => {
    const { pincode } = formData;

    if (pincode) {
      const requestData = {
        type: "FetchShopLocation",
        pincode: formData.pincode,
      };

      const requestDataString = JSON.stringify(requestData);

      const EncData = EncryptPayload(requestDataString);
      const PayloadEnc = EncData[0].payload.toString();
      const IvEnc = EncData[0].iv.toString();

      try {
        const checkResponse = await axios.post(
          "/custLocationRIC.php",
          PayloadEnc,
          {
            headers: {
              "Content-Type": "text/plain",
              Authorization: Authorization,
              EncAuth: IvEnc,
            },
          }
        );

        const encAuthHeaderValue = checkResponse.headers["encauth"];
        const decryptedData = DecryptPayload(
          checkResponse.data,
          encAuthHeaderValue
        );

        const parsedData = JSON.parse(decryptedData);

        if (parsedData.status === "success") {
          const { state, district, location } = parsedData.data[0];
          setSelectedLocation(null);
          setLocationData({
            state,
            district,
            locations: location,
          });
          setFormData((prevData) => ({
            ...prevData,
            state: state || "",
            district: district || "",
          }));
        } else if (parsedData.status === "error") {
          alert(parsedData.message);
          setFormData({
            pincode: "",
            state: "",
            district: "",
            location: "",
          });
          setSelectedLocation(null);
          setLocationData({
            state: "",
            district: "",
            locations: [],
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  //** Location Fetch End **/

  //** Add customer Data Start **/

  // Ownership Select option
  const [selectedOwnership, setSelectedOwnership] = useState(null);
  const handleOwnershipChange = (option) => {
    setSelectedOwnership(option);
    setFormData({
      ...formData,
      ownership: option ? option.value : "",
      constitution: option ? option.value : "",
    });
  };

  const [formData, setFormData] = useState({
    businessName: "",
    constitution: "",
    business: "",
    ownerName: "",
    ownerMobile: "",
    secondaryMobile: "",
    gstin: "",
    emailID: "",
    pan: "",
    aadhar: "",
    pincode: "",
    state: "",
    district: "",
    location: "",
    locationID: "",
    doorNo: "",
    streetAddress1: "",
    streetAddress2: "",
    landmark: "",
    referredBy: "",
    referrerMobile: "",
    otp: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //API call for submitting customer data
  const handleSubmitCustomer = async () => {
    const requestData = {
      type: "AddCustomer",
      customer_name: formData.businessName,
      constitution: formData.constitution,
      nature_of_business: formData.business,
      owner_directors_name: formData.ownerName,
      customer_mobile_number: formData.ownerMobile,
      secondary_mobile_number: formData.secondaryMobile,
      gstin: formData.gstin,
      email_id: formData.emailID,
      customer_pan_number: formData.pan,
      aadhar_number: formData.aadhar,
      pincode: formData.pincode,
      state: formData.state,
      district: formData.district,
      pickup_location: formData.location,
      loc_id: formData.locationID,
      door_no: formData.doorNo,
      street_address_1: formData.streetAddress1,
      street_address_2: formData.streetAddress2,
      landmark: formData.landmark,
      referred_by: formData.referredBy,
      referrers_mobile_number: formData.referrerMobile,
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post(
        "/custRegisterRIC.php",
        PayloadEnc,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: Authorization,
            EncAuth: IvEnc,
          },
        }
      );

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
        alert(parsedData.message);
        setFormData({
          businessName: "",
          constitution: "",
          business: "",
          ownerName: "",
          ownerMobile: "",
          secondaryMobile: "",
          gstin: "",
          emailID: "",
          pan: "",
          aadhar: "",
          pincode: "",
          state: "",
          district: "",
          location: "",
          locationID: "",
          doorNo: "",
          streetAddress1: "",
          streetAddress2: "",
          landmark: "",
          referredBy: "",
          referrerMobile: "",
        });
        setSelectedOwnership(null);
        setSelectedLocation(null);
        setLocationData({
          state: "",
          district: "",
          locations: [],
        });
        navigate("/login");
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //** Add Customer Data End **/

  //** Generate OTP Start **/
  const handleGetOTP = async (e) => {
    e.preventDefault();

    const {
      businessName,
      constitution,
      business,
      ownerName,
      ownerMobile,
      gstin,
      emailID,
      pan,
      aadhar,
      pincode,
      state,
      district,
      location,
      doorNo,
      streetAddress1,
      landmark,
    } = formData;
    if (
      !businessName ||
      !constitution ||
      !business ||
      !ownerName ||
      !ownerMobile ||
      !pan ||
      !aadhar ||
      !pincode ||
      !state ||
      !district ||
      !location ||
      !doorNo ||
      !streetAddress1 ||
      !landmark
    ) {
      alert("All Fields are Required");
      return;
    } else if (ownerMobile.length !== 10) {
      alert("Owner Mobile Number should be 10 digits");
      return;
    } else if (pan.length !== 10) {
      alert("PAN Number should be 10 characters");
      return;
    } else if (aadhar.length !== 12) {
      alert("Aadhar Number should be 12 digits");
      return;
    }
    if (gstin) {
      if (gstin.length !== 15) {
        alert("GSTIN should be 15 digits.");
        return;
      }
      if (!emailID) {
        alert("Email ID is required when GSTIN is provided.");
        return;
      }
    }

    const requestData = {
      type: "OTPGenerate",
      customer_mobile_number: formData.ownerMobile,
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post(
        "/custRegisterRIC.php",
        PayloadEnc,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: Authorization,
            EncAuth: IvEnc,
          },
        }
      );

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
        setButtonState("otp");
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  //** Generate OTP End **/

  //** Verify OTP Start **/

  const handleSubmitOTP = async (e) => {
    e.preventDefault();

    const requestData = {
      type: "OTPVerify",
      customer_mobile_number: formData.ownerMobile,
      otp: formData.otp,
    };

    const requestDataString = JSON.stringify(requestData);

    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();

    try {
      const checkResponse = await axios.post(
        "/custRegisterRIC.php",
        PayloadEnc,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: Authorization,
            EncAuth: IvEnc,
          },
        }
      );

      const encAuthHeaderValue = checkResponse.headers["encauth"];
      const decryptedData = DecryptPayload(
        checkResponse.data,
        encAuthHeaderValue
      );

      const parsedData = JSON.parse(decryptedData);

      if (parsedData.status === "success") {
        handleSubmitCustomer();
      } else if (parsedData.status === "error") {
        alert(parsedData.message);
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  //** Verify OTP End **/
  return (
    <>
      <nav
        className="navbar"
        style={{
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#00172d",
          height: "80px",
        }}
      >
        <div
          className="actions text-light"
          style={{ cursor: "pointer", fontSize: "30px" }}
        >
          RADIANT INSTA CREDIT
        </div>
      </nav>
      <div className="container-fluid py-4">
        <div className="card shadow mb-4" style={{ width: "100%" }}>
          <div className="card-header py-3 d-sm-flex align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold text-primary">
              Customer Registration
            </h6>
            <Link className="" to="/login" style={{ textDecoration: "none" }}>
              Already Registered? Login
            </Link>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <form autoComplete="off">
                {buttonState !== "register" ? (
                  <div
                    className="card shadow mb-4"
                    style={{ width: "30%", margin: "auto" }}
                  >
                    <div className="card-header py-3 d-sm-flex align-items-center justify-content-between mb-4">
                      <h6 className="m-0 font-weight-bold text-primary">OTP</h6>
                    </div>
                    <div className="form-group col-md-12">
                      <input
                        type="text"
                        className="form-control form-control-user"
                        id=""
                        name="otp"
                        placeholder="Enter OTP"
                        value={formData.otp}
                        onChange={handleInputChange}
                      />
                      <br />
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubmitOTP}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <table className="table table-bordered" width="100%">
                      <thead className="text-white">
                        <tr className="bg-gradient-primary">
                          <th colSpan="7">
                            <div align="center">Customer Details</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-dark">
                        <tr>
                          <td>
                            <div className="form-group flex-fill">
                              <label className="text-gray-900">
                                Business / Shop Name{" "}
                                <span className="text-lg text-danger">*</span>
                              </label>
                              <textarea
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="businessName"
                                placeholder="Ex-Krishna Sweets"
                                value={formData.businessName}
                                onChange={handleInputChange}
                              ></textarea>
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label className="text-gray-900">
                                Type of Ownership{" "}
                                <span className="text-lg text-danger">*</span>
                              </label>
                              <Select
                                value={selectedOwnership}
                                onChange={handleOwnershipChange}
                                options={ownership}
                                placeholder="Select Ownership"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group flex-fill">
                              <label className="text-gray-900">
                                Nature of Business / Business Category{" "}
                                <span className="text-lg text-danger">*</span>
                              </label>
                              <textarea
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="business"
                                placeholder="Ex-SuperMarket"
                                value={formData.business}
                                onChange={handleInputChange}
                              ></textarea>
                            </div>
                          </td>
                          <td>
                            <div className="form-group flex-fill">
                              <label className="text-gray-900">
                                Owner's / Director's Name{" "}
                                <span className="text-lg text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="ownerName"
                                placeholder="Owner's / Director's Name"
                                value={formData.ownerName}
                                onChange={handleInputChange}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group flex-fill">
                              <label className="text-gray-900">
                                Owner's / Director's Mobile Number
                                <span className="text-lg text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="ownerMobile"
                                placeholder="Owner's / Director's Mobile Number"
                                value={formData.ownerMobile}
                                onChange={handleInputChange}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-group flex-fill">
                              <label className="text-gray-900">
                                Secondary Mobile Number{" "}
                                <span className="text-lg text-danger"></span>
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="secondaryMobile"
                                placeholder="Secondary Mobile Number"
                                value={formData.secondaryMobile}
                                onChange={handleInputChange}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group flex-fill">
                              <label className="text-gray-900">
                                GSTIN(Mandatory if GST bill required){" "}
                                <span className="text-lg text-danger"></span>
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="gstin"
                                placeholder="GSTIN"
                                value={formData.gstin}
                                onChange={handleInputChange}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group flex-fill">
                              <label className="text-gray-900">Email</label>{" "}
                              <span className="text-lg text-danger"></span>
                              <input
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="emailID"
                                placeholder="Enter Email"
                                value={formData.emailID}
                                onChange={handleInputChange}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group flex-fill">
                              <label className="text-gray-900">
                                Owner's / Company PAN Number{" "}
                                <span className="text-lg text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="pan"
                                placeholder="PAN No (Name must Match)"
                                value={formData.pan}
                                onChange={handleInputChange}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group flex-fill">
                              <label className="text-gray-900">
                                Owner's / Director's Aadhar Number
                                <span className="text-lg text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="aadhar"
                                placeholder="Aadhar No (Name must Match)"
                                value={formData.aadhar}
                                onChange={handleInputChange}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                    >
                      <thead className="text-white">
                        <tr className="bg-gradient-primary">
                          <th colSpan="7">
                            <div align="center">Billing / Owner Address</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-dark">
                        <tr>
                          <td>
                            <div className="form-group flex-fill">
                              <label className="text-gray-900">
                                Door No{" "}
                                <span className="text-lg text-danger">*</span>{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="doorNo"
                                placeholder="Enter Door No"
                                value={formData.doorNo}
                                onChange={handleInputChange}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group flex-fill">
                              <label className="text-gray-900">
                                Street Address 1{" "}
                                <span className="text-lg text-danger">*</span>
                              </label>
                              <textarea
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="streetAddress1"
                                placeholder="Enter Address 1"
                                value={formData.streetAddress1}
                                onChange={handleInputChange}
                              ></textarea>
                            </div>
                          </td>
                          <td>
                            <div className="form-group flex-fill">
                              <label className="text-gray-900">
                                Street Address 2{" "}
                                <span className="text-lg text-danger"></span>
                              </label>
                              <textarea
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="streetAddress2"
                                placeholder="Enter Address 2"
                                value={formData.streetAddress2}
                                onChange={handleInputChange}
                              ></textarea>
                            </div>
                          </td>
                          <td>
                            <div className="form-group flex-fill">
                              <label className="text-gray-900">
                                Landmark{" "}
                                <span className="text-lg text-danger">*</span>{" "}
                              </label>
                              <textarea
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="landmark"
                                placeholder="Enter Landmark"
                                value={formData.landmark}
                                onChange={handleInputChange}
                              ></textarea>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="form-group">
                              <label className="text-gray-900">
                                Pincode{" "}
                                <span className="text-lg text-danger">*</span>{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="pincode"
                                value={formData.pincode}
                                //onChange={handleInputChange}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    pincode: e.target.value,
                                  })
                                }
                                onBlur={handleLocation}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label className="text-gray-900">
                                State{" "}
                                <span className="text-lg text-danger">*</span>{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="state"
                                placeholder="State"
                                value={formData.state}
                                readOnly
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label className="text-gray-900">
                                District{" "}
                                <span className="text-lg text-danger">*</span>{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="district"
                                placeholder="District"
                                value={formData.district}
                                readOnly
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label className="text-gray-900">
                                Location{" "}
                                <span className="text-lg text-danger">*</span>{" "}
                              </label>
                              <Select
                                value={selectedLocation}
                                onChange={handleLocationChange}
                                options={options}
                                placeholder="Select Location"
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                    >
                      <thead className="text-white">
                        <tr className="bg-gradient-primary">
                          <th colSpan="5">
                            <div align="center">Referred By</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-dark">
                        <tr>
                          <td>
                            <div className="form-group">
                              <label className="text-gray-900">
                                Employee ID / Name{" "}
                                <span className="text-lg text-danger"></span>
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="referredBy"
                                placeholder="Ex-RAD0001 or Raja"
                                value={formData.referredBy}
                                onChange={handleInputChange}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group">
                              <label className="text-gray-900">
                                Referrer's Mobile Number{" "}
                                <span className="text-lg text-danger"></span>
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-user"
                                id=""
                                name="referrerMobile"
                                placeholder="Referrer's Mobile Number"
                                value={formData.referrerMobile}
                                onChange={handleInputChange}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}

                {buttonState === "register" ? (
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={handleGetOTP}
                  >
                    Get OTP
                  </button>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      </div>
      <footer className="sticky-footer">
        <div className="footer">
          <div className="copyright text-center my-auto">
            <span className="text-light">
              Copyright &copy; Radiant Cash Management Services Ltd 2024
            </span>
            <span className="float-right text-light">
              <Link className="text-light" to="">
                Privacy and Policy
              </Link>{" "}
              |
              <Link to="" className="text-light">
                {" "}
                Terms and Conditions
              </Link>{" "}
              |
              <Link to="" className="text-light">
                {" "}
                Services with Pricing
              </Link>{" "}
              |
              <Link to="" className="text-light">
                {" "}
                Refund and Cancellation Policy
              </Link>
            </span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Register;
