import React, { useEffect, useState } from "react";
import axios from "../axios/url";
import { Link, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../App.css";
import EncryptPayload from "../../utils/EncryptPayload";
import DecryptPayload from "../../utils/DecryptPayload";
import Authorization from "../../utils/Authorization";

const SubscriptionConfirmation = () => {
  const location = useLocation();
  const [selectRow, SetSelectRow] = useState(null);
  const { pointidPassed, pointsDataPassed } = location.state;
  const pointid = pointidPassed;
  const [pointsData, setpointsData] = useState(pointsDataPassed);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [plan, setPlan] = useState([]);
  const [loading, setLoading] = useState(true);
  var tprice =
    pointsData.length > 0 &&
    pointsData.reduce((sumprice, row) => sumprice + Number(row.price), 0).toFixed(2);
  var tgst =
    pointsData.length > 0 &&
    pointsData.reduce((sumgst, row) => sumgst + Number(row.gst), 0).toFixed(2);
  var tgrand =
    pointsData.length > 0 &&
    pointsData.reduce((sumgrand, row) => sumgrand + Number(row.final_price), 0).toFixed(2);

  useEffect(() => {
    setpointsData(filteredData => {
      const newPoints = new Set(pointid);
      return filteredData.filter((frow) => newPoints.has(frow.point_id.toString()));
    });
  },[pointid]);

  useEffect(() => {
    const requestData = {
      AcePointRequest: {
        AcePointBody: {
          point_id: "RSC108578",
        },
        AcePointHeader: {
          serviceRequestVersion: "1.0",
          serviceRequestId: "AcePointSubscriptionPlan",
        },
      },
    };
    const requestDataString = JSON.stringify(requestData);
    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();
    const LoadPlanDetails = async () => {
      try {
        const responsePlan = await axios.post(
          "/SubscriptionPlanACE.php",
          PayloadEnc,
          {
            headers: {
              "Content-Type": "text/plain",
              Authorization: Authorization,
              EncAuth: IvEnc,
            },
          }
        );

        const encAuthHeaderValue = responsePlan.headers["encauth"];
        const decryptedData = DecryptPayload(
          responsePlan.data,
          encAuthHeaderValue
        );

        const parsedData = JSON.parse(decryptedData);
        setPlan(parsedData);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    LoadPlanDetails();
  }, [isModalOpen]);

  const handleModalOpen = (index) => {
    SetSelectRow(index);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    SetSelectRow(null);
  };

  const handlePlanChange = (subscription, price, gst, final_price) => {
    if (selectRow !== null) {
      const finalSelectRow = pointsData.map((pointrow, index) =>
        selectRow === index
          ? { ...pointrow, subscription, price, gst, final_price }
          : pointrow
      );
      setpointsData(finalSelectRow);
      handleModalClose();
    } else {
      const finalSelectRow = pointsData.map((pointrow) => ({
        ...pointrow,
        subscription,
        price,
        gst,
        final_price,
      }));
      setpointsData(finalSelectRow);
      handleModalClose();
    }
  };

  return (
    <div className="container-fluid">
      <Modal
        fullscreen="true"
        size="lg"
        show={isModalOpen}
        onHide={() => handleModalClose()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Change Subscription
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectRow === null ? (
            <p className="text-danger text-center font-weight-bold">
              Please select the subscription to apply all selected points!
            </p>
          ) : null}
          <table className="table table-bordered" width="100%">
            <thead className="text-white small">
              <tr className="bg-gradient-primary">
                <th>Subscription Name</th>
                <th>Price</th>
                <th>GST</th>
                <th>Grand Total</th>
                <th>Description</th>
                <th>Choose Plan</th>
              </tr>
            </thead>
            <tbody className="text-dark">
              {!loading &&
              plan.status.data &&
              pointid.length > 0 &&
              plan.status.data.length > 0 ? (
                plan.status.data.map((plandata, index) => (
                  <tr key={index}>
                    <td className="font-weight-bold text-danger">
                      {plandata.subscription}
                    </td>
                    <td>{plandata.sub_price}</td>
                    <td>{plandata.gst}</td>
                    <td>{plandata.total_amount}</td>
                    <td>{plandata.description}</td>
                    <td>
                      <button
                        onClick={() =>
                          handlePlanChange(
                            plandata.subscription,
                            plandata.sub_price,
                            plandata.gst,
                            plandata.total_amount
                          )
                        }
                        className="btn btn-grad"
                        type="button"
                      >
                        Choose
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">Please choose the points for subscription</td>
                </tr>
              )}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-sm-flex align-items-center justify-content-between mb-4">
          <h6 className="m-0 font-weight-bold text-primary">
            Select Subscription Plan
            {/* <button className="btn btn-primary" onClick={() => handleModalOpen(null)} type="button">Bulk Subscription</button><br />
            <small className="text-danger">(Apply subscription to all the selected points with one click)</small> */}
          </h6>
          <Link
            to="/subscription"
            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
          >
            <i className="fab fa-cc-amazon-pay fa-sm text-white-50"></i> Back
          </Link>
        </div>
        <div className="card-body">
          <table className="table table-borderless">
            <tbody>
              <td>
                <h5 className="font-weight-bold">
                  Selected points: {pointid.length}
                </h5>
              </td>
              {!loading && plan.status.data.length > 0
                ? plan.status.data.map((planrow, index) => (
                    <>
                      <td>
                        <h5 className="font-weight-bold">
                          {planrow.subscription}:
                          {pointsData.length > 0 &&
                            pointsData.filter(
                              (row) => row.subscription === planrow.subscription
                            ).length}
                        </h5>
                      </td>
                    </>
                  ))
                : null}
            </tbody>
          </table>
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive tableFixHead">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                >
                  <thead className="text-white">
                    <tr className="bg-gradient-primary">
                      <th>Shop ID</th>
                      <th>Pincode</th>
                      <th>Location</th>
                      <th>Subscription Plan</th>
                      <th>Plan Price</th>
                      <th>GST</th>
                      <th>Grant Total</th>
                      <th>Choose Subscription Plan</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-900">
                    {loading ? (
                      <tr>
                        <td colSpan="6">
                          <img src="/img/loading.gif" alt="Loading..." />
                        </td>
                      </tr>
                    ) : pointsData.length > 0 &&
                      plan.status.data &&
                      plan.status.data.length > 0 ? (
                      pointsData.map((pointData, index) => (
                        <tr key={index}>
                          <td>{pointData.point_id}</td>
                          <td>{pointData.pincode}</td>
                          <td>{pointData.location}</td>
                          <td>{pointData.subscription}</td>
                          <td>
                            Rs.
                            {pointData.price}
                          </td>
                          <td>
                            Rs.
                            {pointData.gst}
                          </td>
                          <td>
                            Rs.
                            {pointData.final_price}
                          </td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={() => handleModalOpen(index)}
                            >
                              Change Plan
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">No Records Found</td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan="4" className="text-right font-weight-bold">
                        Total
                      </td>
                      <td className="font-weight-bold">Rs.{tprice}</td>
                      <td className="font-weight-bold">Rs.{tgst}</td>
                      <td className="font-weight-bold">Rs.{tgrand}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colSpan="8" className="text-center">
                        <Link
                          to={{ pathname: "/subscriptionreview" }}
                          state={{
                            pointidPassed: pointid,
                            pointsDataPassed: pointsData,
                          }}
                          className="btn btn-sm btn-danger"
                        >
                          Review Payment
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionConfirmation;
