import React from "react";

const Contact = () => {
  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Contact Us</h1>
      </div>
      <h3>RADIANT CASH MANAGEMENT SERVICES LIMITED</h3>
      <p>
        Radiant Building, # 4/3 Raju Nagar, Okkiyam Thoraipakkam, Chennai 600096.
      </p>
      <p><b className="font-weight-bold">Email:</b> instacreditenquiry@radiantcashservices.com / businessdevelopment@radiantcashservices.com</p>
      <p><b className="font-weight-bold">Telephone:</b> 044-49044904</p>
      <p><b className="font-weight-bold">Fax:</b> 044 4852 3448</p>
      <p><b className="font-weight-bold">Mobile:</b> +91 9136947003 / 9136947004 / 73581 08453</p>
    </div>
  );
};

export default Contact;
