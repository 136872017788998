import cryptoJs from "crypto-js";
import SecretKey from "./SecretKey";

const DecryptPayload = (encryptedData, ivBase64) => {
  try {
    const iv = cryptoJs.enc.Base64.parse(ivBase64);

    const encryptedDataWordArray = cryptoJs.enc.Base64.parse(encryptedData);

    const decrypted = cryptoJs.AES.decrypt(
      { ciphertext: encryptedDataWordArray },
      cryptoJs.enc.Utf8.parse(SecretKey),
      {
        iv: iv,
        mode: cryptoJs.mode.CBC,
        padding: cryptoJs.pad.Pkcs7,
      }
    );

    const base64Decrypted = cryptoJs.enc.Base64.parse(
      decrypted.toString(cryptoJs.enc.Utf8)
    );
    const decryptedText = cryptoJs.enc.Utf8.stringify(base64Decrypted);

    return decryptedText;
  } catch (error) {
    console.error("Decryption failed:", error);
  }
};

export default DecryptPayload;
