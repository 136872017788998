import React, { useEffect, useState } from "react";
import axios from "../axios/url";
//import useRazorpay from "react-razorpay-integration";
import { Link, useLocation } from "react-router-dom";
import "../../App.css";
import EncryptPayload from "../../utils/EncryptPayload";
import DecryptPayload from "../../utils/DecryptPayload";
import Authorization from "../../utils/Authorization";

const SubscriptionReview = () => {
  const location = useLocation();
  const { pointidPassed, pointsDataPassed } = location.state;
  const pointid = pointidPassed;
  const pointsData = pointsDataPassed;
  //const [Razorpay] = useRazorpay();
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState([]);
  const tgrand =
    pointsData.length > 0 &&
    pointsData.reduce((sumgrand, row) => sumgrand + Number(row.final_price), 0).toFixed(2);
  //const payAmount = tgrand;

  useEffect(() => {
    const requestData = {
      AcePointRequest: {
        AcePointBody: {
          point_id: "RSC108578",
        },
        AcePointHeader: {
          serviceRequestVersion: "1.0",
          serviceRequestId: "AcePointSubscriptionPlan",
        },
      },
    };
    const requestDataString = JSON.stringify(requestData);
    const EncData = EncryptPayload(requestDataString);
    const PayloadEnc = EncData[0].payload.toString();
    const IvEnc = EncData[0].iv.toString();
    const LoadPlanDetails = async () => {
      try {
        const responsePlan = await axios.post(
          "/SubscriptionPlanACE.php",
          PayloadEnc,
          {
            headers: {
              "Content-Type": "text/plain",
              Authorization: Authorization,
              EncAuth: IvEnc,
            },
          }
        );

        const encAuthHeaderValue = responsePlan.headers["encauth"];
        const decryptedData = DecryptPayload(
          responsePlan.data,
          encAuthHeaderValue
        );

        const parsedData = JSON.parse(decryptedData);
        setPlan(parsedData);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    LoadPlanDetails();
  }, []);

  // const handlePayment = async (e) => {
  //   e.preventDefault();
  //   const order = await axios.post(
  //     "/RazCreateOrder.php",
  //     {
  //       amount: payAmount
  //     }
  //   ); //  Create order on your backend

  //   const options = {
  //     key: "rzp_test_OGQhWiNSTYoszs",
  //     amount: payAmount * 100,
  //     currency: "INR",
  //     name: "Radiant Cash Management Services",
  //     description: "Subscription Payment",
  //     image: "img/logo.png",
  //     order_id: order.id,
  //     handler: function (response) {
  //       alert(response.razorpay_payment_id);
  //       alert(response.razorpay_order_id);
  //       alert(response.razorpay_signature);
  //     },
  //     prefill: {
  //       name: "Radiant Cash Management Services",
  //       email: "rameshv@radiantcashservices.com",
  //       contact: "9943594869",
  //     },
  //     notes: {
  //       address: "Radiant Corporate Office",
  //     },
  //     theme: {
  //       color: "#3399cc",
  //     },
  //   };

  //   const rzp1 = new Razorpay(options);

  //   rzp1.on("payment.failed", function (response) {
  //     alert(response.error.code);
  //     alert(response.error.description);
  //     alert(response.error.source);
  //     alert(response.error.step);
  //     alert(response.error.reason);
  //     alert(response.error.metadata.order_id);
  //     alert(response.error.metadata.payment_id);
  //   });

  //   rzp1.open();
  // };

  return (
    <div className="container-fluid">
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-sm-flex align-items-center justify-content-between mb-4">
          <h6 className="m-0 font-weight-bold text-primary">Review Payment</h6>
          <Link
            to={{ pathname: "/subscriptionconfirmation" }}
            state={{ pointidPassed: pointid, pointsDataPassed: pointsData }}
            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
          >
            <i className="fab fa-cc-amazon-pay fa-sm text-white-50"></i> Back
          </Link>
        </div>
        <div className="card-body">
          <table className="table table-borderless">
            <tbody>
              <td>
                <h5 className="font-weight-bold">
                  Selected points: {pointid.length}
                </h5>
              </td>
              {!loading && plan.status.data.length > 0
                ? plan.status.data.map((planrow, index) => (
                    <>
                      <td>
                        <h5 className="font-weight-bold">
                          {planrow.subscription}:
                          {pointsData.length > 0 &&
                            pointsData.filter(
                              (row) => row.subscription === planrow.subscription
                            ).length}
                        </h5>
                      </td>
                    </>
                  ))
                : null}
            </tbody>
          </table>
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive tableFixHead">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                >
                  <thead className="text-white">
                    <tr className="bg-gradient-primary">
                      <th>Shop ID</th>
                      <th>Pincode</th>
                      <th>Location</th>
                      <th>Subscription Plan</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-900">
                    {loading ? (
                      <tr>
                        <td colSpan="6">
                          <img src="/img/loading.gif" alt="Loading..." />
                        </td>
                      </tr>
                    ) : pointsData.length > 0 ? (
                      pointsData.map((pointData, index) => (
                        <tr key={index}>
                          <td>{pointData.point_id}</td>
                          <td>{pointData.pincode}</td>
                          <td>{pointData.location}</td>
                          <td>{pointData.subscription}</td>
                          <td>
                            Rs.
                            {pointData.final_price}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">No Records Found</td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan="4" className="text-right font-weight-bold">
                        Grand Total
                      </td>
                      <td className="font-weight-bold">Rs.{tgrand}</td>
                    </tr>
                    <tr>
                      <td colSpan="5" className="text-center">
                        <button type="button" className="btn btn-sm btn-danger">
                          Proceed to Pay
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionReview;
